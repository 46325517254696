import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import NestedForm from "stimulus-rails-nested-form"
import Reveal from "stimulus-reveal-controller"

window.Stimulus = Application.start()
const context = require.context("@controllers/admin", true, /\.js$/)

Stimulus.load(definitionsFromContext(context))
Stimulus.register("nested-form", NestedForm)
Stimulus.register("reveal", Reveal)
