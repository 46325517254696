import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'displayable'
  ]

  toggle (event) {
    if (event.target.checked) {
      this.displayableTarget.classList.remove('d-none')
    } else {
      this.displayableTarget.classList.add('d-none')
    }
  }
}
