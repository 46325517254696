/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'

import "stylesheets/application.sass"
import "stylesheets/tailwind.sass"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import executeRecaptcha from "@utils/grecaptcha"

// Stimulus
import "@controllers"

if (process.env.NODE_ENV === "development") {
  import("stylesheets/development.sass")
}

// Copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
require.context("./images", true)

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage
window.Rails = Rails
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
})
